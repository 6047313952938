<template>
  <CardLayout>
    <Error404 v-if="error.statusCode === 404" />
    <Error500 v-else />
  </CardLayout>
</template>

<script>
import CardLayout from '@/components/layout/CardLayout'
import Error404 from '@/components/errors/404'
import Error500 from '@/components/errors/500'

export default {
  components: { CardLayout, Error404, Error500 },

  props: {
    error: { type: Object, required: true },
  }, // you can set a custom layout for the error page
}
</script>
