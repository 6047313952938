// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/hooray.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-441a7c73] .icon-hooray{width:71px;height:13px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}[data-v-441a7c73] .v-sheet.v-snack__wrapper{border-radius:0}[data-v-441a7c73] .v-sheet.v-snack__wrapper:not(.v-sheet--outlined){box-shadow:none}[data-v-441a7c73] .v-snack__wrapper.theme--dark{width:100%;max-width:none;margin:0}[data-v-441a7c73] .v-snack__content{text-align:center;font-size:1rem}[data-v-441a7c73] .green{background:linear-gradient(#e9ece8,#f9fcf7)!important;border-top:1px solid #569e34;border-bottom:1px solid #569e34}[data-v-441a7c73] .green .v-snack__content{color:#306117}[data-v-441a7c73] .red{background:#feeff0!important;border-top:1px solid #dc4140;border-bottom:1px solid #dc4140}[data-v-441a7c73] .red .v-snack__content{color:#bb1817}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
