import uniqBy from 'lodash/uniqBy'
import dayjs from 'dayjs'
import { ORDER } from '@/helpers/ideas-ordering'

const categoriesParams = () => ({
  topToday: {
    created_after: dayjs().startOf('day').format('YYYY-MM-DD'),
    order: ORDER.LIKES,
  },
  topWeek: {
    created_after: dayjs().startOf('week').format('YYYY-MM-DD'),
    order: ORDER.LIKES,
  },
  topMonth: {
    created_after: dayjs().startOf('month').format('YYYY-MM-DD'),
    order: ORDER.LIKES,
  },
  top: {
    order: ORDER.LIKES,
  },
  new: {
    order: ORDER.DATE_DESC,
  },
})

const defaultCards = [
  { id: 'end-of-stack', type: 'end-of-stack' },
]

export const state = () => ({
  category: 'new',
  query: null,
  loading: false,
  cards: [...defaultCards],
  count: 0,
  currIndex: 0,
  nextPage: 1,
  createdIdeaId: null,
  draftIdeaId: '',
  indexRedirect: false,
  searchReturnUrl: '',
})

export const actions = {
  async getIdea ({ commit, getters, rootGetters }, { app, shortId }) {
    try {
      commit('GET_IDEA')
      const res = await this.$axios.get(`ideas/${shortId}/`)
      commit('GET_IDEA_OK', res.data)
    } catch (e) {
      commit('GET_IDEA_ERR', e)
      app.$sentry.captureException(e)
      throw e
    }
  },

  async getIdeas ({ commit, state, rootState }, { app, pageSize = 25 }) {
    try {
      commit('GET_IDEAS')
      if (state.nextPage === null) { return }
      const page = state.nextPage
      const params = { page, ...categoriesParams()[state.category], q: state.query, page_size: pageSize }
      const res = await this.$axios.get('ideas/', { params })
      commit('GET_IDEAS_OK', res.data)
      if (! rootState.auth.user && ! this.$cookies.get('hide_newsletter')) {
        commit('INSERT_NEWSLETTER_CARDS', { page, pageSize })
      }
    } catch (e) {
      commit('GET_IDEAS_ERR', e)
      app.$sentry.captureException(e)
      throw e
    }
  },

  async updateIndex (
    { commit, dispatch, state },
    { app, idx, direction, shortId },
  ) {
    let index = idx
    if (typeof direction !== 'undefined') {
      index = Math.max(state.currIndex + direction, 0)
    }

    if (shortId) {
      index = state.cards.findIndex(i => i.short_id === shortId)
    }

    // Load next page when approaching end of page
    if (index > state.cards.length - 5 && ! state.loading && state.nextPage) {
      await dispatch('getIdeas', { app })
    }

    if (index >= state.cards.length) {
      index = Math.max(state.cards.length - 1, 0)
    }

    commit('UPDATE_CURR_INDEX', index)
  },

  async likeIdea ({ commit, app }, idea) {
    const res = await this.$axios.post('likes/', { object_type: 'Idea', object_id: idea.id })
    commit('UPDATE_IDEA', { ...idea, is_liked: true, likes_count: idea.likes_count + 1 })
    return res.data
  },

  async unlikeIdea ({ commit, app }, idea) {
    await this.$axios.delete('likes/', { data: { object_type: 'Idea', object_id: idea.id } })
    commit('UPDATE_IDEA', { ...idea, is_liked: false, likes_count: idea.likes_count - 1 })
  },

  async saveIdea ({ commit, app }, idea) {
    const res = await this.$axios.post('saves/', { object_type: 'Idea', object_id: idea.id })
    commit('UPDATE_IDEA', { ...idea, is_saved: true, saves_count: idea.saves_count + 1 })
    return res.data
  },

  async unsaveIdea ({ commit, app }, idea) {
    await this.$axios.delete('saves/', { data: { object_type: 'Idea', object_id: idea.id } })
    commit('UPDATE_IDEA', { ...idea, is_saved: false, saves_count: idea.saves_count - 1 })
  },
}

export const mutations = {
  INSERT_NEWSLETTER_CARDS (state, { page, pageSize }) {
    if (page === 1 && state.cards.length > 5) {
      state.cards.splice(5, 0, { type: 'newsletter' }) // Insert at 5
    }
    const start = Math.max(10, Math.ceil(((page - 1) * pageSize) / 10) * 10) // Round up to tens
    for (let i = start; i < state.cards.length; i += 10) {
      state.cards.splice(i, 0, { type: 'newsletter' }) // Insert at 10, 20, 30, etc...
    }
  },

  REMOVE_NEWSLETTER_CARDS (state) {
    const totalBeforeCurrIndex = state.cards.reduce((n, c, i) => i <= state.currIndex && c.type === 'newsletter' ? n + 1 : n, 0)
    state.cards = state.cards.filter(c => c.type !== 'newsletter')
    state.currIndex -= totalBeforeCurrIndex
  },

  INSERT_CARD (state, { index, card }) {
    if (state.cards.length > index) { index = state.cards.length }
    state.cards.splice(index, 0, card)
  },

  UPDATE_CREATED (state, id) {
    state.createdIdeaId = id
  },

  GET_IDEA (state) {
    state.loading = true
  },

  GET_IDEA_OK (state, idea) {
    const ideas = state.cards
    const idx = ideas.findIndex(i => i.id === idea.id)
    if (idx !== -1) {
      ideas.splice(idx, 1)
    }
    ideas.unshift({ ...idea, type: 'idea' })
    state.loading = false
  },

  GET_IDEA_ERR (state) {
    state.loading = false
  },

  GET_IDEAS (state) {
    state.loading = true
  },

  GET_IDEAS_OK (state, data) {
    state.cards.splice(state.cards.length - 1, 0, ...data.results.map(i => ({ ...i, type: 'idea' })))
    state.cards = uniqBy(state.cards, 'id') // Remove duplicates
    state.nextPage = data.next
    state.loading = false
  },

  GET_IDEAS_ERR (state, e) {
    state.loading = false
  },

  UPDATE_CATEGORY (state, category) {
    state.category = category || state.category
  },

  UPDATE_CURR_INDEX (state, index) {
    state.currIndex = index
  },

  UPDATE_IDEA (state, idea) {
    const idx = state.cards.findIndex(i => i.id === idea.id)
    if (idx === -1) { return }
    Object.assign(state.cards[idx], { ...idea, type: 'idea' })
  },

  DELETE_IDEA (state, id) {
    const idx = state.cards.findIndex(i => i.id === id)
    if (idx === -1) { return }
    state.cards.splice(idx, 1)
  },

  RESET_CARDS (state, keepSearchReturnUrl = false) {
    state.cards = [...defaultCards]
    state.loading = false
    state.count = 0
    state.currIndex = 0
    state.nextPage = 1
    state.query = null
    if (! keepSearchReturnUrl) {
      state.searchReturnUrl = ''
    }
  },

  UPDATE_DRAFT_IDEA (state, id) {
    state.draftIdeaId = id
  },

  UPDATE_INDEX_REDIRECT (state, redirect) {
    state.indexRedirect = redirect
  },

  UPDATE_QUERY (state, query) {
    state.query = query
  },

  UPDATE_SEARCH_RETURN_URL (state, url) {
    state.searchReturnUrl = url
  },
}

export const getters = {
  currCard: (state) => {
    return state.cards[state.currIndex]
  },

  firstIdea: (state) => {
    return state.cards.find(i => i.type === 'idea')
  },

  firstCard: (state) => {
    return state.cards.length && state.cards[0]
  },
}
