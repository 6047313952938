<template>
  <v-toolbar class="desktop desktop-header hidden-sm-and-down" color="white">
    <v-container>
      <v-row>
        <v-col cols="auto">
          <v-toolbar-title>
            <!-- Logo on top left corner -->
            <nuxt-link to="/" class="logo d-flex flex-column justify-center align-center mt-1">
              <img class="logo__bulb" src="~/assets/images/logo.svg">
              <img class="logo__text" src="~/assets/images/logo_text.svg">
            </nuxt-link>
          </v-toolbar-title>
        </v-col>
        <v-col />
        <v-col cols="auto">
          <Links />
        </v-col>
      </v-row>
    </v-container>
  </v-toolbar>
</template>

<script>
import Links from './Links'

export default {
  components: { Links },
}
</script>

<style scoped lang="scss">
.desktop {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1) !important;
  width: 100%;

  .backButon {
    margin-right: 20px;
    margin-top: -10px;

    color: $primary-color;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
  }

  .logo {
    cursor: pointer;
    &__bulb {
      width: 30px;
    }

    &__text {
      width: 90px;
    }
  }
}
</style>
