import { createLogger } from 'vuex'

export const actions = {
  async nuxtServerInit ({ dispatch, commit }) {
    const token = this.$cookies.get('token')
    if (! token) { return }
    await commit('auth/SET_TOKEN', token)
    try {
      await dispatch('auth/getUser')
    } catch (e) {
      //
    }
  },
}

export const state = () => ({
  routerLoading: false,
  routerLoadingEnabled: true,
  loadingMessage: null,
  loadingImage: null,
  loadingDelay: 100,
  navigated: false,
})

export const mutations = {
  SET_LOADING_MESSAGE (state, message) {
    state.loadingMessage = message
  },

  SET_LOADING_IMAGE (state, image) {
    state.loadingImage = image
  },

  SET_LOADING_DELAY (state, delay) {
    state.loadingDelay = delay
  },

  SET_ROUTER_LOADING (state, enabled) {
    state.routerLoading = enabled
  },

  SET_ROUTER_LOADING_ENABLED (state, enabled) {
    state.routerLoadingEnabled = enabled
  },

  NAVIGATED (state) {
    state.navigated = true
  },
}

export const plugins = []

if (process.env.NODE_ENV === 'development') {
  plugins.push(createLogger({
    transformer (state) {
      if (process.server) {
        return ''
      } // To reduce console data
      return state
    },
    mutationTransformer ({ type, payload }) {
      if (process.server) {
        return type
      }
      return { type, payload }
    },
    actionTransformer ({ type, payload }) {
      if (process.server) {
        return type
      }
      return { type, payload }
    },
  }))
}
