<template>
  <span ref="container" class="relative">
    <slot />
    <span ref="slider" class="slider" />
    <span ref="highlight" class="highlight" />
  </span>
</template>

<script>
import screenSize from '~/mixins/screen-size'

export default {
  mixins: [screenSize],

  props: {
    paddingLeft: { type: Number, default: 0 },
    paddingRight: { type: Number, default: 0 },
  },

  mounted () {
    this.$nextTick(() => {
      this.initSlider()
    })
  },

  methods: {
    setSliderToActive () {
      if (! this.$refs.container) {
        return
      }
      const active = this.$refs.container.querySelector('.link.active')
      this.setSliderToEl(active)
      this.setHighlightToEl(active && active.querySelector('span'))
    },

    setSliderToEl (el) {
      if (! el) { return }
      const slider = this.$refs.slider
      const rect = el.getBoundingClientRect()
      const parentRect = this.$refs.container.getBoundingClientRect()

      const left = rect.left - parentRect.left
      const top = rect.top - parentRect.top

      slider.style.width = `${rect.width - (this.breakpointNo < 2 ? 0 : this.paddingRight)}px`
      slider.style.left = `${left + (this.breakpointNo < 2 ? 0 : this.paddingLeft)}px`
      slider.style.top = `${top + rect.height}px`
    },

    setHighlightToEl (el) {
      if (! el) { return }
      const highlight = this.$refs.highlight
      const rect = el.getBoundingClientRect()
      const parentRect = this.$refs.container.getBoundingClientRect()

      const left = rect.left - parentRect.left
      const top = rect.top - parentRect.top

      highlight.style.width = `${rect.width}px`
      highlight.style.left = `${left}px`
      highlight.style.top = `${top + rect.height / 2}px`
    },

    initSlider () {
      if (! this.$refs.container) {
        return
      }
      const that = this
      const links = this.$refs.container.querySelectorAll('.link')

      for (let i = 0; i < links.length; i++) {
        links[i].addEventListener('mouseenter', function () {
          that.setSliderToEl(this)
        })
        links[i].addEventListener('click', () => this.setSliderToActive())
        links[i].addEventListener('mouseleave', () => this.setSliderToActive())
      }

      setTimeout(() => this.setSliderToActive(), 50)

      window.addEventListener('resize', () => this.setSliderToActive())
    },
  },
}
</script>

<style scoped lang="scss">
.slider {
  position: absolute;
  transition: all 0.35s ease-in-out;
  border-bottom: 3px solid $secondary-color;
}
.highlight {
  position: absolute;
  height: 10px;
  transition: all 0.35s ease-in-out;
  background: rgba(91, 65, 187, 0.2);
}
</style>
