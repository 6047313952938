export const state = () => ({
  token: null,
  user: null,
  loading: false,
})

export const actions = {
  async login ({ commit }, form) {
    commit('LOGIN')
    try {
      const res = await this.$axios.post('login/', form)
      commit('LOGIN_OK', res.data)
      this.$cookies.set('token', res.data.token, { path: '/', maxAge: 60 * 60 * 24 * 30 })
    } catch (e) {
      commit('LOGIN_ERR')
      throw e
    }
  },

  loginFromData ({ commit }, data) {
    commit('LOGIN_OK', data)
    this.$cookies.set('token', data.token, { path: '/', maxAge: 60 * 60 * 24 * 30 })
  },

  async register ({ commit }, form) {
    commit('REGISTER')
    try {
      const res = await this.$axios.post('register/', form)
      commit('REGISTER_OK', res.data)
      this.$cookies.set('token', res.data.token, { path: '/', maxAge: 60 * 60 * 24 * 30 })
    } catch (e) {
      commit('REGISTER_ERR')
      throw e
    }
  },

  async reset ({ commit }, form) {
    commit('RESET')
    try {
      const res = await this.$axios.post('reset/', form)
      commit('RESET_OK', res.data)
      this.$cookies.set('token', res.data.token, { path: '/', maxAge: 60 * 60 * 24 * 30 })
    } catch (e) {
      commit('RESET_ERR')
      throw e
    }
  },

  logout ({ commit }) {
    this.$cookies.remove('token')
    commit('LOGOUT_OK')
  },

  async getUser ({ commit }) {
    commit('GET_USER')
    try {
      const res = await this.$axios.get('users/profile/')
      commit('GET_USER_OK', res.data)
    } catch (e) {
      commit('GET_USER_ERR')
      throw e
    }
  },

  async updateProfile ({ commit }, form) {
    commit('UPDATE_PROFILE')
    try {
      const res = await this.$axios.post('users/profile/', form)
      commit('UPDATE_PROFILE_OK', res.data)
    } catch (e) {
      commit('UPDATE_PROFILE_ERR')
      throw e
    }
  },
}

export const mutations = {
  LOGIN (state) {
    state.loading = true
  },

  LOGIN_OK (state, { user, token }) {
    state.loading = false
    state.user = user
    state.token = token
  },

  LOGIN_ERR (state) {
    state.loading = false
    state.token = null
  },

  REGISTER (state) {
    state.loading = true
  },

  REGISTER_OK (state, { user, token }) {
    state.loading = false
    state.user = user
    state.token = token
  },

  REGISTER_ERR (state) {
    state.loading = false
    state.token = null
  },

  RESET (state) {
    state.loading = true
  },

  RESET_OK (state, { user, token }) {
    state.loading = false
    state.user = user
    state.token = token
  },

  RESET_ERR (state) {
    state.loading = false
    state.token = null
  },

  LOGOUT_OK (state) {
    state.user = null
    state.token = null
  },

  GET_USER (state) {
    state.loading = true
  },

  GET_USER_OK (state, { user }) {
    state.loading = false
    state.user = user
  },

  GET_USER_ERR (state) {
    state.loading = false
  },

  UPDATE_PROFILE (state) {
    state.loading = true
  },

  UPDATE_PROFILE_OK (state, { user }) {
    state.loading = false
    state.user = user
  },

  UPDATE_PROFILE_ERR (state) {
    state.loading = false
  },

  UPDATE_USER (state, user) {
    state.user = user
  },

  SET_TOKEN (state, token) {
    state.token = token
  },
}
