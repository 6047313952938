<template>
  <div>
    <ask-email-dialog
      :loading="loading"
      :value="showAskEmail"
      :header="askEmailHeader"
      :message="askEmailMessage"
      :show-close-button="showCloseButton"
      :persistent="persistent"
      @input="cancel"
      @cancel="cancel"
      @data="onEmailEntered"
    />

    <ask-password-dialog
      v-model="showAskPassword"
      :loading="loading"
      :show-close-button="showCloseButton"
      :persistent="persistent"
      message="One last step, please enter your password."
      :error="loginError"
      :header="`Welcome back ${name}!`"
      @data="onPasswordEntered"
      @forgot="onForgotPassword"
    />

    <register-dialog
      v-model="showRegistration"
      :loading="loading"
      :show-close-button="showCloseButton"
      :persistent="persistent"
      header="Welcome to Daily Idea!"
      message="One last step, please set a password."
      :errors="registerErrors"
      @data="onRegisterDataEntered"
    />

    <forgot-email-sent-dialog
      v-model="showForgotEmail"
      :loading="loading"
      :show-close-button="showCloseButton"
      :persistent="persistent"
      header="Help is on the way!"
      :message="`A link to reset your password was sent to: ${email}`"
      @resend="onForgotPassword"
      @ok="showForgotEmail = false, showAskEmail = true"
    />

    <welcome-dialog
      v-model="showWelcome"
      :action="action"
      :name="name"
      :email="email"
      :show-close-button="showCloseButton"
      :persistent="persistent"
      :show-back-button="showWeclomeBackButton"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import AskPasswordDialog from '../dialogs/AskPasswordDialog'
import AskEmailDialog from '../dialogs/AskEmailDialog'
import ForgotEmailSentDialog from '@/components/dialogs/ForgotEmailSentDialog'
import RegisterDialog from '~/components/dialogs/RegisterDialog'
import WelcomeDialog from '~/components/dialogs/WelcomeDialog'

export default {
  components: {
    ForgotEmailSentDialog,
    RegisterDialog,
    WelcomeDialog,
    AskEmailDialog,
    AskPasswordDialog,
  },

  props: {
    value: Boolean,
    action: {
      type: String,
      default: 'default',
      validator: val =>
        ['default', 'like', 'save', 'comment', 'share', 'post'].includes(val),
    },

    idea: { type: Object, default: null },
    additionalData: { type: Object, default: () => ({}) },
    next: { type: String, default: '' },
    showCloseButton: { type: Boolean, default: true },
    persistent: { type: Boolean, default: false },
    buttonCancelText: { type: String, default: 'Cancel' },
    showWeclomeBackButton: { type: Boolean, default: false },
    onLogin: { type: Function, default: null },
    onCancel: { type: Function, default: null },
    initialEmail: { type: String, default: null },
  },

  data () {
    return {
      email: '',
      name: '',
      showAskEmail: false,
      showAskPassword: false,
      showRegistration: false,
      showForgotEmail: false,
      showWelcome: false,
      newUser: false,
      loginError: '',
      registerErrors: [],
      loading: false,
    }
  },

  computed: {
    ...mapState({
      user: s => s.auth.user,
    }),

    actionGerund () {
      switch (this.action) {
        case 'like':
          return 'liking'
        case 'save':
          return 'saving'
        default:
          return 'commenting'
      }
    },

    askEmailHeader () {
      if (this.action === 'post') {
        return 'We can’t wait to see your idea!'
      }

      if (this.action === 'share') {
        return 'Become an Idea Machine'
      }

      return 'Who are you?'
    },

    askEmailMessage () {
      switch (this.action) {
        case 'like':
        case 'save':
        case 'comment':
          return `Enter your email address to finish <span class='link-highlight'>${this.actionGerund}</span> this idea.`
        case 'post':
          return 'Enter your email address to get started!'
        case 'share':
          return 'Subscribe to the best ideas on the internet, delivered weekly.<br> Enter your email below to get sign up or log in.'
        default:
          return 'Enter your email so we can help you find the idea later.'
      }
    },
  },

  watch: {
    $route () {
      this.$emit('input', false)
    },

    value (val) {
      if (! val) {
        this.showAskEmail = false
        this.showAskName = false
        this.showWelcome = false
      }
    },
  },

  mounted () {
    if (this.initialEmail) {
      this.onEmailEntered(this.initialEmail)
    } else {
      this.showAskEmail = true
    }
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      register: 'auth/register',
    }),

    ...mapMutations({
      showProgressBar: 'layoutState/showProgressBar',
      hideProgressBar: 'layoutState/hideProgressBar',
    }),

    async onEmailEntered (email) {
      this.email = email.toLowerCase()
      this.loading = true

      try {
        const res = await this.$axios.post('users/exists/', { email: this.email })

        if (res.data.exists) {
          this.name = res.data.user.name
          this.showAskPassword = true
        } else {
          this.showRegistration = true
        }
        this.showAskEmail = false
      } catch (e) {
        console.error(e) // eslint-disable-line
        this.$sentry.captureException(e)
      }
      this.loading = false
    },

    async onPasswordEntered (password) {
      this.loading = true
      this.loginError = ''
      try {
        await this.login({ email: this.email, password })
        this.showAskPassword = false
        this.loginSuccess()
      } catch (e) {
        if (e?.response?.data?.detail) {
          this.loginError = e?.response?.data?.detail
        } else {
          console.error(e) // eslint-disable-line
          this.$sentry.captureException(e)
        }
      }
      this.loading = false
    },

    async onRegisterDataEntered (data) {
      this.loading = true
      this.loginError = ''
      try {
        await this.register({ email: this.email, ...data })
        this.showRegistration = false
        this.loginSuccess()
      } catch (e) {
        if (e?.response?.data?.detail) {
          this.loginError = e?.response?.data?.detail
        } else {
          console.error(e) // eslint-disable-line
          this.$sentry.captureException(e)
        }
      }
      this.loading = false
    },

    async onForgotPassword () {
      this.loading = true
      try {
        await this.$axios.post('forgot/', { email: this.email })
        this.showAskPassword = false
        this.showForgotEmail = true
        this.$notifier.success('Email with reset instructions was sent!')
      } catch (e) {
        console.error(e) // eslint-disable-line
        this.$sentry.captureException(e)
      }
      this.loading = false
    },

    loginSuccess () {
      this.$emit('input', false)
      this.$gtag.event('logged_in', { event_label: this.user.slug })
      if (this.onLogin) {
        this.onLogin({ email: this.email })
      }
    },

    cancel () {
      this.$emit('input', false)
      if (this.onCancel) {
        this.onCancel()
      }
    },
  },
}
</script>
