<template>
  <div class="bg-white flex flex-col flex-1" :class="{ 'bg-brand-bg': greyBg }" hidden>
    <v-progress-linear :indeterminate="true" :active="progressBarActive" :height="2" style="z-index: 999" absolute />
    <div class="loggedInHeader hidden-sm-and-down">
      <DesktopHeader class="z-1" />
    </div>

    <slot name="header" />

    <!-- Router contents -->
    <div class="content-container flex-1 bg-brand-bg">
      <main class="h-full" :class="mainClass" :style="mainStyle">
        <slot />
      </main>
    </div>

    <div v-if="!hideMobileNav" class="hidden-md-and-up sticky-footer">
      <mobile-header class="mobile" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DesktopHeader from './DesktopHeader'
import MobileHeader from './MobileHeader'

export default {
  components: { MobileHeader, DesktopHeader },

  props: {
    greyBg: { type: Boolean, default: false },
    hideMobileNav: { type: Boolean, default: false },
    mainClass: { type: String, default: '' },
    mainStyle: { type: String, default: '' },
  },

  data () {
    return { searchIdeaMode: false }
  },

  computed: {
    progressBarActive () {
      return this.$store.getters['layoutState/progressBarActive']
    },
  },

  mounted () {
    this.$el.removeAttribute('hidden')
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
  },
}
</script>

<style lang="scss">
.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (min-width: $screen-md-min) {
    align-items: center;
  }

  .overlay-card {
    width: 70%;
    margin: 0 auto;
    padding-top: 3rem;
    @media (max-width: $screen-sm-max) {
      width: 95%;
      overflow-y: auto;
    }
  }
}

.v-toolbar__content {
  width: 100%;
}

@media (max-width: $screen-sm-max) {
  .sticky-footer {
    display: flex;
    align-items: center;
    border-top: 2px solid $secondary-color;
    position: fixed;
    bottom: 0;
    z-index: 101;
    width: 100%;

    .mobile {
      height: 75px !important;
    }
  }
}

.grey-bg {
  background-color: $background-grey;
}

#commonHeader {
  height: 100%;
  position: relative;

  .loggedInHeader {
    position: relative;
    z-index: 2;
    width: 100%;
    background: white !important;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
}
</style>
