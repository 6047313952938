const exclude401Logout = [
  'login/',
]

export default ({ store, $config, $axios, error, redirect }) => {
  $axios.setBaseURL($config.apiUrl)

  $axios.onRequest((request) => {
    if (! request.headers) {
      request.headers = {}
    }
    request.headers.accept = 'application/json'

    if (store.state.auth.token) {
      request.headers.Authorization = `Bearer ${store.state.auth.token}`
    }

    return request
  })

  $axios.onResponseError(async (err) => {
    if (err.response.status === 401 && ! exclude401Logout.includes(err.response.config.url)) {
      await store.dispatch('auth/logout')
      if (process.client) {
        location.reload()
      }
    }
    if (err.response.status === 404) {
      error({ statusCode: 404, message: err.message })
      throw err
    }
    throw err
  })
}
