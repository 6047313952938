<template>
  <Layout main-class="py-5">
    <SwipableCard :reduce-height="40" width="70vw">
      <slot />
    </SwipableCard>
  </Layout>
</template>

<script>
import SwipableCard from '@/components/shared/SwipableCard'
import Layout from '~/components/layout/Layout'

export default {
  components: { SwipableCard, Layout },
}
</script>
