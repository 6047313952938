import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c6bef038 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _4754b2e0 = () => interopDefault(import('../pages/community-guidelines.vue' /* webpackChunkName: "pages/community-guidelines" */))
const _029b7057 = () => interopDefault(import('../pages/demo-error.vue' /* webpackChunkName: "pages/demo-error" */))
const _17d53fa2 = () => interopDefault(import('../pages/email-guide.vue' /* webpackChunkName: "pages/email-guide" */))
const _51caf53a = () => interopDefault(import('../pages/example-custom-layout.vue' /* webpackChunkName: "pages/example-custom-layout" */))
const _1e1d06ad = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _e92a756e = () => interopDefault(import('../pages/ideas-cards.vue' /* webpackChunkName: "pages/ideas-cards" */))
const _d87acf24 = () => interopDefault(import('../pages/ideas-not-found.vue' /* webpackChunkName: "pages/ideas-not-found" */))
const _38ffe894 = () => interopDefault(import('../pages/leaderboard.vue' /* webpackChunkName: "pages/leaderboard" */))
const _54d6dfc3 = () => interopDefault(import('../pages/open.vue' /* webpackChunkName: "pages/open" */))
const _5046fe9f = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _fcbe8a80 = () => interopDefault(import('../pages/send-digest.vue' /* webpackChunkName: "pages/send-digest" */))
const _07babaf5 = () => interopDefault(import('../pages/snooze-emails.vue' /* webpackChunkName: "pages/snooze-emails" */))
const _b3a83984 = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _7a4ead9d = () => interopDefault(import('../pages/auth/change-email.vue' /* webpackChunkName: "pages/auth/change-email" */))
const _9347603e = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _c49d2d3e = () => interopDefault(import('../pages/auth/unathorized.vue' /* webpackChunkName: "pages/auth/unathorized" */))
const _03bbe008 = () => interopDefault(import('../pages/ideas/create.vue' /* webpackChunkName: "pages/ideas/create" */))
const _68ea7c29 = () => interopDefault(import('../pages/profile/edit.vue' /* webpackChunkName: "pages/profile/edit" */))
const _943569fe = () => interopDefault(import('../pages/ideas/edit/_shortId.vue' /* webpackChunkName: "pages/ideas/edit/_shortId" */))
const _4c25072e = () => interopDefault(import('../pages/profile/_userSlug/index.vue' /* webpackChunkName: "pages/profile/_userSlug/index" */))
const _1540df9e = () => interopDefault(import('../pages/profile/_userSlug/saved.vue' /* webpackChunkName: "pages/profile/_userSlug/saved" */))
const _eadcd248 = () => interopDefault(import('../pages/profile/_userSlug/settings.vue' /* webpackChunkName: "pages/profile/_userSlug/settings" */))
const _3a51b1dc = () => interopDefault(import('../pages/i/_shortId/_slug.vue' /* webpackChunkName: "pages/i/_shortId/_slug" */))
const _5b99feca = () => interopDefault(import('../pages/unsubscribe/_userId/_token.vue' /* webpackChunkName: "pages/unsubscribe/_userId/_token" */))
const _874f3aae = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _c6bef038,
    name: "about"
  }, {
    path: "/community-guidelines",
    component: _4754b2e0,
    name: "community-guidelines"
  }, {
    path: "/demo-error",
    component: _029b7057,
    name: "demo-error"
  }, {
    path: "/email-guide",
    component: _17d53fa2,
    name: "email-guide"
  }, {
    path: "/example-custom-layout",
    component: _51caf53a,
    name: "example-custom-layout"
  }, {
    path: "/faq",
    component: _1e1d06ad,
    name: "faq"
  }, {
    path: "/ideas-cards",
    component: _e92a756e,
    name: "ideas-cards"
  }, {
    path: "/ideas-not-found",
    component: _d87acf24,
    name: "ideas-not-found"
  }, {
    path: "/leaderboard",
    component: _38ffe894,
    name: "leaderboard"
  }, {
    path: "/open",
    component: _54d6dfc3,
    name: "open"
  }, {
    path: "/privacy",
    component: _5046fe9f,
    name: "privacy"
  }, {
    path: "/send-digest",
    component: _fcbe8a80,
    name: "send-digest"
  }, {
    path: "/snooze-emails",
    component: _07babaf5,
    name: "snooze-emails"
  }, {
    path: "/terms-of-service",
    component: _b3a83984,
    name: "terms-of-service"
  }, {
    path: "/auth/change-email",
    component: _7a4ead9d,
    name: "auth-change-email"
  }, {
    path: "/auth/reset",
    component: _9347603e,
    name: "auth-reset"
  }, {
    path: "/auth/unathorized",
    component: _c49d2d3e,
    name: "auth-unathorized"
  }, {
    path: "/ideas/create",
    component: _03bbe008,
    name: "ideas-create"
  }, {
    path: "/profile/edit",
    component: _68ea7c29,
    name: "profile-edit"
  }, {
    path: "/ideas/edit/:shortId?",
    component: _943569fe,
    name: "ideas-edit-shortId"
  }, {
    path: "/profile/:userSlug",
    component: _4c25072e,
    name: "profile-userSlug"
  }, {
    path: "/profile/:userSlug?/saved",
    component: _1540df9e,
    name: "profile-userSlug-saved"
  }, {
    path: "/profile/:userSlug?/settings",
    component: _eadcd248,
    name: "profile-userSlug-settings"
  }, {
    path: "/i/:shortId?/:slug?",
    component: _3a51b1dc,
    name: "i-shortId-slug"
  }, {
    path: "/unsubscribe/:userId?/:token?",
    component: _5b99feca,
    name: "unsubscribe-userId-token"
  }, {
    path: "/",
    component: _874f3aae,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
