export default {
  data: () => ({
    screenSize: '2xl',
    breakpointNo: 4,
  }),

  beforeMount () {
    if (! process.client) {
      return
    }
    this.updateScreenSize()
    window.addEventListener('resize', this.updateScreenSize)
  },

  methods: {
    updateScreenSize () {
      if (! process.client) {
        return
      }
      if (window.innerWidth >= 1536) {
        this.screenSize = '2xl'
        this.breakpointNo = 4
      } else if (window.innerWidth >= 1280) {
        this.screenSize = 'xl'
        this.breakpointNo = 3
      } else if (window.innerWidth >= 1024) {
        this.screenSize = 'lg'
        this.breakpointNo = 2
      } else if (window.innerWidth >= 768) {
        this.screenSize = 'md'
        this.breakpointNo = 1
      } else {
        this.screenSize = 'sm'
        this.breakpointNo = 0
      }
    },
  },
}
