<template>
  <div class="tw">
    <div class="fixed inset-0 pointer-events-none" style="z-index: 9999;">
      <LottieWrapper
        ref="lottie"
        class="min-h-screen min-w-screen"
        :style="`aspect-ratio: ${aspectRatio};`"
        :name="`confetti-${screenSize}`"
        :options="{animationData: confettiData, loop: false}"
        @anim-created="animCreated"
      />
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import LottieWrapper from '@/components/shared/LottieWrapper'
import dataDesktop from '@/assets/lottie/confetti-desktop.json'
import dataTablet from '@/assets/lottie/confetti-tablet.json'
import dataMobile from '@/assets/lottie/confetti-mobile.json'
import screenSize from '@/mixins/screen-size'

export default {
  components: { LottieWrapper },

  mixins: [screenSize],

  computed: {
    confettiData () {
      switch (this.screenSize) {
        case 'sm': return dataMobile
        case 'md': return dataTablet
        default: return dataDesktop
      }
    },

    aspectRatio () {
      switch (this.screenSize) {
        case 'sm': return 0.4618
        case 'md': return 1.4316
        default: return 1.96
      }
    },
  },

  methods: {
    ...mapMutations({
      hideConfetti: 'ui/HIDE_CONFETTI',
    }),

    animCreated (anim) {
      anim.addEventListener('complete', () => this.hideConfetti())
    },
  },
}
</script>
