<template>
  <v-app id="app" class="tw">
    <Nuxt />
    <AppDialog />
    <AppNotifier />
    <GlobalDialogs />
    <Confetti v-if="showConfetti" />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import AppDialog from '~/components/dialogs/AppDialog'
import AppNotifier from '~/components/layout/AppNotifier'
import GlobalDialogs from '~/components/layout/GlobalDialogs'
import Confetti from '@/components/layout/Confetti'

export default {
  components: {
    Confetti,
    AppDialog,
    AppNotifier,
    GlobalDialogs,
  },

  computed: {
    ...mapState({
      showConfetti: s => s.ui.confetti,
    }),
  },

  watch: {
    $route: {
      deep: true,
      handler () {
        if (! this.navigated) { this.$store.commit('NAVIGATED') }
      },
    },
  },
}
</script>

<style lang="scss">
@import '~assets/style/base';
@import '~assets/style/modals';
</style>
