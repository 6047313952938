export const state = () => ({
  confetti: false,
})

export const mutations = {
  SHOW_CONFETTI (state) {
    state.confetti = true
  },

  HIDE_CONFETTI (state) {
    state.confetti = false
  },
}
