import Vue from 'vue'
import GlobalDialogs from '~/components/layout/GlobalDialogs'

const Dialog = {
  install (Vue, options) {
    this.eventBus = new Vue()

    Vue.component('GlobalDialogs', GlobalDialogs)

    Vue.prototype.$dialog = {
      show (modalName, params) {
        return new Promise((resolve, reject) => {
          Dialog.eventBus.$emit('show', modalName, params, resolve)
        })
      },
      hide (modalName) {
        Dialog.eventBus.$emit('hide', modalName)
      },
      reset () {
        Dialog.eventBus.$emit('reset')
      },
    }
  },
}

Vue.use(Dialog)

export default Dialog
