<template>
  <default-dialog
    :value="value"
    :show-cancel-button="false"
    :show-close-button="false"
    :persistent="persistent"
    :header="header"
    :subheader="message"
    :image-path="require('assets/images/dialogs/forgot_password.svg')"
    :button-ok-loading="loading"
    button-ok-text="Take me to log in"
    @input="v => $emit('input', v)"
    @cancel="$emit('cancel')"
    @ok="$emit('ok')"
  >
    <template #after-footer>
      <p class="mt-5">
        It can take several minutes for this email to arrive. If it has not arrived, please double check your email address, spam and promotional folders.
      </p>
      <p class="text-center">
        Didn’t receive a link? <a role="button" @click="$emit('resend')">Resend it</a>
      </p>
    </template>
  </default-dialog>
</template>

<script>
import DefaultDialog from '~/components/dialogs/DefaultDialog'

export default {
  components: {
    DefaultDialog,
  },

  props: {
    value: Boolean,
    loading: Boolean,
    header: { type: String, default: 'What’s your email?' },
    message: { type: String, default: 'Your Email?' },
    persistent: { type: Boolean, default: false },
    error: { type: String, default: '' },
  },
}
</script>
