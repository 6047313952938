<template>
  <sliding-underline :padding-left="20" :padding-right="30">
    <span class="links d-flex flex-row align-center justify-space-between">
      <nuxt-link :class="{ active: page === 'explore' }" class="link" to="/ideas-cards">
        <i v-if="page === 'explore'" class="icon-dailyidea-bulb-on" :style="`color: ${activeColor}; font-size: 26px;`" />
        <i v-else class="icon-dailyidea-bulb-off" :style="`color: ${inactiveColor}; font-size: 26px;`" />
        <span class="ml-md-1">Explore</span>
      </nuxt-link>
      <nuxt-link :class="{ active: page === 'post', disabled: !slug }" :to="{ name: 'ideas-create' }" class="link">
        <i class="icon-dailyidea-post" :style="`color: ${iconColor('post')}; font-size: 26px;`" />
        <span class="ml-md-2">Post</span>
      </nuxt-link>
      <nuxt-link :class="{ active: page === 'profile', disabled: !slug }" :to="slug ? `/profile/${slug}` : '/auth/unathorized'" class="link">
        <i v-if="page === 'profile'" class="icon-dailyidea-profile-on" :style="`color: ${activeColor}; font-size: 26px;`" />
        <i v-else class="icon-dailyidea-profile-off" :style="`color: ${iconColor('profile')}; font-size: 26px;`" />
        <span class="ml-md-2">My Ideas</span>
      </nuxt-link>
    </span>
  </sliding-underline>
</template>

<script>
import { mapState } from 'vuex'
import SlidingUnderline from '../layout/SlidingUnderline'

export default {
  components: {
    SlidingUnderline,
  },

  data () {
    return {
      activeColor: '#ffc61e',
      inactiveColor: '#151125',
      disabledColor: '#acabb2',
      pages: {
        'i-shortId-slug': 'explore',
        'ideas-all': 'explore',
        'ideas-me': 'profile',
        profile: 'profile',
        'profile-userSlug-saved': 'profile',
        'profile-userSlug': 'profile',
        'ideas-saved': 'profile',
        'ideas-create': 'post',
        'profile-edit': 'profile',
      },
    }
  },

  computed: {
    ...mapState({
      user: s => s.auth.user,
      slug: s => s.auth.user?.slug,
    }),

    page () {
      const pageOn = this.pages[this.$route.name]

      if (pageOn === 'profile' && this.$route.params.userSlug !== this.user?.slug) {
        return null
      }

      return pageOn
    },
  },

  methods: {
    iconColor (page) {
      return this.slug
        ? this.page !== page
          ? this.inactiveColor
          : this.activeColor
        : this.disabledColor
    },
  },
}
</script>

<style scoped lang="scss">
.link {
  cursor: pointer;
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  @media (min-width: $screen-md-min) {
    padding: 1.1rem;
  }

  @media (max-width: $screen-sm-max) {
    flex-direction: column;
  }
  > span {
    margin-top: 0.2em;
  }

  .img {
    height: 20px;
    width: 40px;
    @media (min-width: $screen-md-min) {
      padding-right: 15px;
    }
  }
}

.active {
  height: 100%;

  .active-bulb {
    height: 30px;
    margin: -2px 0;

    @media (min-width: $screen-md-min) {
      padding-right: 9px;
      padding-bottom: 3px;
    }

    @media (max-width: $screen-sm-max) {
      /* Prevent image from offsetting itself due to light rays */
      margin-top: -10px;
    }
  }
}
</style>
