<template>
  <div ref="lottie" v-bind="$attrs" :key="name" v-on="$listeners" />
</template>

<script>
import lottie from 'lottie-web'

export default {
  props: {
    name: { type: String, required: true },
    options: { type: Object, required: true },
  },

  mounted () {
    this.anim = lottie.loadAnimation({
      container: this.$refs.lottie,
      renderer: 'svg',
      loop: this.options.loop !== false,
      autoplay: this.options.autoplay !== false,
      animationData: this.options.animationData,
      rendererSettings: this.options.rendererSettings,
    })
    this.$emit('anim-created', this.anim)
  },
}
</script>
