<template>
  <div>
    <component :is="dialog.name" v-for="dialog in dialogs" :key="dialog.id" v-model="dialog.show" v-bind="dialog.attrs" />
  </div>
</template>

<script>
import Dialog from '@/plugins/dialog.client'
import AuthFlow from '@/components/auth/AuthFlow'

const dialogDefaults = {
  name: null,
  show: null,
  attrs: {},
}

export default {
  components: {
    AuthFlow,
  },

  data: () => ({
    dialogs: [],
  }),

  watch: {
    dialogs: {
      deep: true,
      handler (dialogs) {
        dialogs.forEach((m) => {
          if (m.show === false) { this.removeDialog(m.name) }
        })
      },
    },
  },

  beforeMount () {
    Dialog.eventBus.$on('show', (name, attrs) => {
      this.addDialog(name, attrs)
    })

    Dialog.eventBus.$on('hide', (name) => {
      this.removeDialog(name)
    })

    Dialog.eventBus.$on('reset', () => {
      this.resetDialogs()
    })
  },

  methods: {
    addDialog (name, attrs) {
      if (! this.$options.components[name]) {
        return
      }
      this.removeDialog(name)

      const id = Math.random().toString()
      const dialog = { ...dialogDefaults, id, name, attrs }
      this.dialogs.push(dialog)
      this.$nextTick(() => {
        dialog.show = true
      })
      return id
    },

    removeDialog (name) {
      const idx = this.dialogs.findIndex(m => m.name === name)
      if (idx !== -1) {
        this.dialogs[idx].show = false
        this.$nextTick(() => {
          this.dialogs.splice(idx, 1)
        })
      }
    },

    resetDialogs () {
      this.dialogs = []
    },
  },
}
</script>
