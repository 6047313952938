<template>
  <validation-observer v-slot="{ valid, validated, handleSubmit }">
    <default-dialog
      :value="value"
      :button-ok-disabled="!validated || !valid"
      :show-cancel-button="false"
      :show-close-button="false"
      :persistent="persistent"
      :header="header"
      :subheader="message"
      :image-path="require('assets/images/dialogs/welcome.svg')"
      :button-ok-loading="loading"
      button-ok-text="Login"
      @input="v => $emit('input', v)"
      @cancel="$emit('cancel')"
      @ok="handleSubmit(onOk)"
    >
      <v-text-field-with-validation
        v-model="form.password"
        name="password"
        rules="required|min:6|max:100"
        prepend-inner-icon="icon-dailyidea-lock-closed"
        label="Set a password"
        :error-messages="errors.password"
        :type="show4 ? 'text' : 'password'"
        :append-icon="show4 ? 'icon-dailyidea-eye-on' : 'icon-dailyidea-eye-disabled'"
        @keydown.enter="handleSubmit(onOk)"
        @click:append="show4 = !show4"
      />
      <v-text-field-with-validation
        v-model="form.name"
        name="name"
        rules="required|max:100"
        prepend-inner-icon="icon-dailyidea-user-card"
        label="Your name"
        :error-messages="errors.name"
        @keydown.enter="handleSubmit(onOk)"
      />
    </default-dialog>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import DefaultDialog from '~/components/dialogs/DefaultDialog'
import VTextFieldWithValidation from '~/components/validation/VTextFieldWithValidation'

export default {
  components: {
    VTextFieldWithValidation,
    ValidationObserver,
    DefaultDialog,
  },

  props: {
    value: Boolean,
    loading: Boolean,
    header: { type: String, default: 'What’s your email?' },
    message: { type: String, default: 'Your Email?' },
    persistent: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
  },

  data: () => ({
    form: {
      password: '',
      name: '',
    },

    show4: false,
  }),

  methods: {
    onOk () {
      this.$emit('data', this.form)
    },
  },
}
</script>
