import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ app, $config }) => {
  Vue.use(VueGtag, {
    config: { id: $config.gtagId },
    appName: $config.name,
    pageTrackerTemplate: (to, from) => {
      return to.fullPath
    },
  }, app.router)
}
