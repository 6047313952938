<template>
  <validation-provider
    ref="provider"
    v-slot="{ errors }"
    :name="$attrs.name"
    :rules="rules"
  >
    <v-text-field
      v-model="innerValue"
      :error-messages="errorMessages ? [...errors, errorMessages] : errors"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: { ValidationProvider },

  props: {
    rules: { type: [Object, String], default: '' },
    // must be included in props
    value: { type: null, default: '' },
    errorMessages: { type: String, default: '' },
    valid: Boolean,
  },

  data: () => ({ innerValue: '' }),

  watch: {
    // Handles internal model changes.
    async innerValue (newVal) {
      this.$emit('input', newVal)
      const res = await this.$refs.provider.validate()
      this.$emit('update:valid', res.valid)
    },

    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    },
  },

  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input__prepend-inner {
    padding-right: 14px !important;
  }
}
</style>
