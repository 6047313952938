<template>
  <v-snackbar
    v-model="visible"
    :timeout="timeout"
    :color="color"
    right
    top
    @click.native="visible = false"
  >
    <div class="d-flex justify-center align-center">
      <i v-if="iconBefore" :class="iconBefore" class="mr-4 mb-1 text-lg" />
      <div>
        {{ message }}
      </div>
      <i v-if="iconAfter" :class="iconAfter" class="ml-4 mb-1 text-lg" />
    </div>
  </v-snackbar>
</template>

<script>
import Notifier from '@/plugins/app-notifier.client'

const initialData = {
  visible: false,
  message: '',
  color: '',
  timeout: 2000,
  iconBefore: null,
  iconAfter: null,
}

export default {
  data () {
    return Object.assign({}, initialData)
  },

  beforeMount () {
    Notifier.eventBus.$on('show', params => this.show(params))
    Notifier.eventBus.$on('success', params => this.success(params))
    Notifier.eventBus.$on('error', params => this.error(params))
  },

  methods: {
    close () {
      this.visible = false
    },

    confirm () {
      this.visible = false
    },

    show (params) {
      Object.assign(this, Object.assign({}, initialData), params)
      this.visible = true
    },

    success (params) {
      if (typeof params === 'string') {
        params = { message: params, iconBefore: 'icon-dailyidea-circle-checkmark' }
      }
      this.show(Object.assign(params, { color: 'green' }))
    },

    error (params) {
      if (typeof params === 'string') {
        params = { message: params, iconBefore: 'icon-dailyidea-circle-x' }
      }
      this.show(Object.assign(params, { color: 'red' }))
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .icon-hooray {
    width: 71px; height: 13px;
    background-image: url(~/assets/images/hooray.svg);
  }

  .v-sheet.v-snack__wrapper {
    border-radius: 0;
  }
  .v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
    box-shadow: none;
  }
  .v-snack__wrapper.theme--dark {
    width: 100%;
    max-width: none;
    margin: 0;
  }
  .v-snack__content {
    text-align: center;
    font-size: 1rem;
  }

  .green {
    background: linear-gradient(#e9ece8, #f9fcf7) !important;
    border-top: 1px solid #569e34;
    border-bottom: 1px solid #569e34;
    .v-snack__content {
      color: #306117;
    }
  }
  .red {
    background: #feeff0 !important;
    border-top: 1px solid #dc4140;
    border-bottom: 1px solid #dc4140;
    .v-snack__content {
      color: #bb1817;
    }
  }
}
</style>
