import Vue from 'vue'
import AppDialog from '~/components/dialogs/AppDialog'

const Dialog = {
  install (Vue, options) {
    this.eventBus = new Vue()

    Vue.component('AppDialog', AppDialog)

    Vue.prototype.$appDialog = {
      show (params) {
        return new Promise((resolve, reject) => {
          Dialog.eventBus.$emit('show', params, resolve)
        })
      },
    }
  },
}

Vue.use(Dialog)

export default Dialog
