<!-- eslint-disable vue/no-v-html -->
<template>
  <v-dialog
    :value="value"
    :content-class="contentClass"
    :persistent="persistent"
    max-width="600"
    @input="v => $emit('input', v)"
  >
    <div v-if="showCloseButton" class="closeBtn text-right">
      <i class="icon-dailyidea-x text-lg" role="button" @click="hide" />
    </div>

    <slot name="header">
      <!-- Header -->
      <section class="modalHeader">
        <h3 v-if="header">
          {{ header }}
        </h3>
        <p v-if="subheader" v-html="subheader" />
        <v-img
          v-if="imagePath"
          :max-width="maxImageWidth"
          :src="imagePath"
          max-height="180"
          class="mx-auto modalTopImage mb-8"
          contain
        />
      </section>
    </slot>

    <!-- Body -->
    <section class="modalBody">
      <slot />
    </section>

    <slot name="footer">
      <section class="modalFooter">
        <!-- Action Buttons -->
        <div
          class="text-right d-flex flex-row justify-space-around align-center"
        >
          <v-btn
            v-if="showCancelButton"
            class="cancelBtn"
            rounded
            text
            color="primary"
            @click="cancelAndHide"
          >
            {{ buttonCancelText }}
          </v-btn>
          <span v-if="showCancelButton && showOkButton" class="grey-bar" />
          <v-btn
            v-if="showOkButton"
            :disabled="buttonOkDisabled"
            :loading="buttonOkLoading"
            :class="{ error: error }"
            color="primary"
            @click="$emit('ok')"
          >
            {{ buttonOkText }}
          </v-btn>
        </div>
      </section>
    </slot>
    <slot name="after-footer" />
  </v-dialog>
</template>

<script>
export default {
  props: {
    header: { type: String, default: '' },
    subheader: { type: String, default: '' },
    error: { type: Boolean, default: false },
    imagePath: { type: String, default: '' },
    value: Boolean,
    buttonOkText: { type: String, default: 'Ok' },
    buttonCancelText: { type: String, default: 'Cancel' },
    buttonOkDisabled: { type: Boolean, default: false },
    buttonOkLoading: { type: Boolean, default: false },
    showCancelButton: { type: Boolean, default: true },
    showOkButton: { type: Boolean, default: true },
    contentClass: { type: String, default: 'modal' },
    maxImageWidth: { type: Number, default: 180 },
    showCloseButton: { type: Boolean, default: true },
    persistent: { type: Boolean, default: false },
  },

  methods: {
    cancelAndHide () {
      this.$emit('cancel')
      this.hide()
    },

    hide () {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog {
    border-radius: 10px;
  }
}
</style>
