<template>
  <validation-observer v-slot="{ valid, validated, handleSubmit }">
    <default-dialog
      :value="value"
      :button-ok-disabled="!validated || !valid"
      :show-cancel-button="false"
      :show-close-button="showCloseButton"
      :persistent="persistent"
      :header="header"
      :subheader="message"
      :image-path="require('assets/images/dialogs/dialog_email.svg')"
      :button-ok-loading="loading"
      button-ok-text="Next"
      @input="v => $emit('input', v)"
      @cancel="$emit('cancel')"
      @ok="handleSubmit(onOk)"
    >
      <v-text-field-with-validation
        v-model="email"
        name="Email"
        rules="required|email|max:100"
        prepend-inner-icon="icon-dailyidea-email"
        label="Your email address"
        @keydown.enter="handleSubmit(onOk)"
      />
    </default-dialog>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import DefaultDialog from '~/components/dialogs/DefaultDialog'
import VTextFieldWithValidation from '~/components/validation/VTextFieldWithValidation'

export default {
  components: {
    VTextFieldWithValidation,
    ValidationObserver,
    DefaultDialog,
  },

  props: {
    value: Boolean,
    loading: Boolean,
    header: { type: String, default: 'What’s your email?' },
    message: { type: String, default: 'Your Email?' },
    showCloseButton: { type: Boolean, default: true },
    persistent: { type: Boolean, default: false },
  },

  data: () => ({ email: '' }),

  methods: {
    onOk () {
      this.$emit('data', this.email)
    },
  },
}
</script>
